.App {
  text-align: center;
  background-color: #222;
  padding: 20px;
  font: 16px Barlow, sans-serif;
  color: #999;
  height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header {
  font: bold 48px Pacifico, cursive;
  margin: 5px;
  color: #fe019a;
}

.subHeader {
  font-weight: bold;
}

.listItem {
  position: relative;
  background-color: #555;
  margin: 10px auto;
  width: 220px;
  padding: 10px 4px;
}

.closeItem {
  position: absolute;
  top: 0;
  right: 3px;
  color: #f00;
  font-weight: bold;
}

.voteButtons {
  font-family: sans-serif;
  position: absolute;
  top: 0;
  right: 3px;
  font-weight: bold;
}

input[type='button'],
input[type='submit'],
button {
  font: 14px Barlow, sans-serif;
  padding: 8px 20px;
  margin: 7px;
  color: #222;
  background-color: #888;
  border: none;
}

input[type='text'] {
  font: 14px Barlow, sans-serif;
  padding: 6px;
  margin: 7px;
}

input[type='button']:active,
input[type='submit']:active,
button:active {
  background-color: #555;
}

input:focus,
button:focus {
  outline-width: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
